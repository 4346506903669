@import "~@/erp/styles/variables/variables.scss";






























































































































































































































::v-deep {
  .el-dialog__body {
    position: relative;
    height: 100%;
    padding: 0 !important;
  }

  .el-dialog__header {
    display: none;
  }

  .el-textarea .el-textarea__inner {
    padding: 10px;
    height: 100% !important;
    border: none;
  }
}

.preview-warp-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;

  .loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: auto;
  }

  .download-btn {
    position: fixed;
    right: 60px;
    top: 13px;
    z-index: 1;
    font-size: 26px;
    color: #606266;
    cursor: pointer;

    &.doc,
    &.docx,
    &.xlsx,
    &.xls {
      color: #fff;
    }
  }

  .close {
    position: fixed;
    right: 20px;
    top: 13px;
    z-index: 1;
    font-size: 26px;
    cursor: pointer;

    &.pdf {
      color: #fff;
      background: rgba(50, 54, 57, 1);
    }

    &.doc,
    &.docx,
    &.xlsx,
    &.xls {
      color: #fff;
    }
  }

  .img-box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.img-box-auto {
      height: auto;
      width: auto;
    }
  }

  .txt-box {
    height: 100%;
    width: 100%;
    border: none;
  }

  .tips {
    font-size: 16px;
  }
}
